import React, { useEffect, useState, useCallback } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import chunk from 'lodash.chunk';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import GetStartedCenter from 'components/GetStartedCenter';
import ArchivePostSingle from 'components/ArchivePostSingle';

// import { Main } from 'styles/blog';
import 'styles/blog.scss';

const ProductUpdates = ({
  data: {
    allContentfulBlogPost: { edges: allPosts = [] },
  },
  location,
}) => {
  const [posts, setPosts] = useState([]);

  const [pages, setPages] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);

  const [category, setCategory] = useState('');

  const postsPerPage = 21;

  const categoryToggle = useCallback(
    (input) => () => {
      if (input !== category) {
        setCurrentPage(0);
        setPosts([]);
        setPages([]);
      }
      setCategory(() => input);
    },
    [],
  );

  const getBlogPosts = useCallback(
    () =>
      allPosts.filter((p) => {
        if (category) {
          if (p.node.category?.some((e) => e.name === category)) {
            setPosts([]);
            return true;
          }
          setPosts([]);
          return false;
        }
        setPosts([]);
        return true;
      }),
    [allPosts, category],
  );

  useEffect(() => {
    setPages(chunk(getBlogPosts(), postsPerPage));
  }, [allPosts, category]);

  useEffect(() => {
    setPosts((state) => [
      ...state,
      ...(pages[currentPage] ? pages[currentPage] : []),
    ]);
  }, [pages, currentPage]);

  const loadMore = () => {
    if (currentPage < pages.length) {
      setCurrentPage((state) => state + 1);
    }
  };

  return (
    <Layout location={location}>
      {({ toggleSignup }) => (
        <>
          <Meta
            title="Product Updates | ruttl"
            description="Improve your knowledge and stay updated with new trends in designing with ruttl's blog page that is regularly updated with latest content on web designing."
            url="https://ruttl.com/product-updates/"
          />
          <main className="blog-styled-main">
            <div className="blog-filters-main">
              <div className="container">
                <h1 className="product-updates-heading">Knowledge Box</h1>
                <div className="filter-block">
                  <button
                    className={`${category ? '' : 'active'}`}
                    type="button"
                    onClick={categoryToggle('')}>
                    All
                  </button>
                  <button
                    className={`${
                      category === 'Design and Development' ? 'active' : ''
                    }`}
                    type="button"
                    onClick={categoryToggle('Design and Development')}>
                    Design and Development
                  </button>
                  <button
                    className={`${category === 'Productivity' ? 'active' : ''}`}
                    type="button"
                    onClick={categoryToggle('Productivity')}>
                    Productivity
                  </button>
                  <button
                    className={`${category === 'Useful Tips' ? 'active' : ''}`}
                    type="button"
                    onClick={categoryToggle('Useful Tips')}>
                    Useful Tips
                  </button>
                </div>
              </div>
            </div>
            <section className="post-list-main product-posts">
              {/* <div className="container">
              <p className="bold post-list-main--subhead">
                Our thoughts and observations on business, partners, nutrients,
                productivity, Science, &amp; more.
              </p>
            </div> */}
              {true && (
                <div className="container">
                  <div className="flex flex-wrap" style={{ margin: '0 -15px' }}>
                    {posts.map(({ node }) => (
                      <React.Fragment key={node.id}>
                        <ArchivePostSingle
                          link={`/blog/${node.slug}/`}
                          title={node.title}
                          image={node.archiveThumbnail.fluid}
                          excerpt={node.excerpt.excerpt}
                          category={node.category}
                          noDetail
                          date={node?.publishDate}
                          author={
                            node?.guestAuthor
                              ? node?.guestName
                              : node?.author?.name
                          }
                          authorImage={
                            node?.guestAuthor
                              ? node?.guestImage?.fluid
                              : node?.author?.image?.fluid
                          }
                        />
                      </React.Fragment>
                    ))}
                  </div>
                  {currentPage + 1 < pages.length ? (
                    <div style={{ marginTop: '20px' }}>
                      <button
                        type="button"
                        className="button"
                        onClick={loadMore}>
                        Load more
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
            </section>
            <GetStartedCenter toggleSignup={toggleSignup} />
          </main>
        </>
      )}
    </Layout>
  );
};

ProductUpdates.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default ProductUpdates;

export const pageQuery = graphql`
  query KnowledgeBoxQuery {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: {
        knowledgeBox: { eq: true }
        category: {
          elemMatch: { name: { nin: ["Product Updates", "Product Videos"] } }
        }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          publishDate(formatString: "MMM DD, YYYY")
          archiveThumbnail {
            fluid(
              maxWidth: 600
              maxHeight: 456
              quality: 70
              resizingBehavior: THUMB
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          excerpt {
            excerpt
          }
          category {
            name
          }
          author {
            id
            name
            image {
              fluid(
                maxWidth: 32
                maxHeight: 32
                quality: 100
                resizingBehavior: THUMB
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          guestAuthor
          guestName
          guestAuthorDescription {
            guestAuthorDescription
          }
          guestImage {
            fluid(maxWidth: 100, maxHeight: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
